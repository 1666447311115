<template>
  <b-card-actions
    ref="adminUsersListActionCard"
    title="Admin Users list"
    action-refresh
    action-collapse
    @refresh="loadAdminUsers"
  >

    <b-table
      ref="refUserListTable"
      class="position-relative"
      :items="adminUsersList"
      responsive
      :fields="fields"
      primary-key="id"
      show-empty
      striped
      hover
      :per-page="perPage"
      :current-page="currentPage"
      empty-text="No matching records found"
    >

      <!-- Column: User -->
      <template #cell(avatar)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="50"
              :text="avatarText(data.item.name)"
              :to="{ name: 'user_data', params: { id: data.item.id } }"
            />
          </template>
          <b-link
            :to="{ name: 'user_data', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap text-dark"
            style="text-decoration: underline"
          >
            {{ data.item.name }}
          </b-link>
          <small class="text-muted">{{ data.item.short_mobile }}</small>
        </b-media>
      </template>

      <!-- Column: Status -->
      <template #cell(approved)="data">
        <b-badge
          pill
          :variant="`light-${resolveUserStatusVariant(data.item.approved)}`"
        >
          <feather-icon
            :icon="`${resolveUserStatusVariantIcon(data.item.approved)}`"
            class="mr-25"
          />
          <span>{{ resolveUserStatusVariantText(data.item.approved) }}</span>
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item @click="approveUserFunction(data.item.id, !data.item.approved)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
          <b-dropdown-item>
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="adminUsersList.length"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card-actions>
</template>

<script>
// eslint-disable-next-line import/extensions
import BCardActions from '@core/components/b-card-actions/BCardActions'
import {
  BTable, BAvatar, BCol, BRow, BPagination, BLink, BMedia, BDropdown, BDropdownItem, BBadge,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'AdminUsers',
  components: {
    BTable,
    BAvatar,
    BCol,
    BRow,
    BLink,
    BMedia,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardActions,
  },

  data() {
    return {
      perPage: 5,
      pageOptions: [5, 10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      avatarText,
      filter: null,
      filterOn: [],
      fields: [
        { key: 'avatar', label: 'USER' },
        { key: 'email', label: 'EMAIL', sortable: true },
        { key: 'approved', label: 'STATUS', sortable: true },
        { key: 'actions' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      adminUsersList: 'users/adminUsersList',
    }),
  },
  async created() {
    await this.loadAdminUsers()
  },
  methods: {
    ...mapActions({
      loadAllAdminUsers: 'get',
      approveUser: 'edit',
    }),
    async loadAdminUsers() {
      await this.loadAllAdminUsers({
        storeName: 'users',
        url: '/admin/users/adminUsers',
        commitUrl: 'users/SET_ADMIN_USERS',
      })
      this.$refs.adminUsersListActionCard.showLoading = false
    },
    async approveUserFunction(userID, approvedStatus) {
      await this.approveUser({
        form: { approved: approvedStatus },
        url: `admin/users/app/${userID}`,
        storeName: 'users',
      }).then(() => {
        this.loadAdminUsers()
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'success',
            text: approvedStatus ? 'Manager Approved Successfully' : 'Manager Disabled Successfully',
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error,
          },
        })
      })
    },
    resolveUserStatusVariant(status) {
      if (status === true) return 'success'
      if (status === false) return 'danger'
      return 'primary'
    },
    resolveUserStatusVariantText(status) {
      if (status === true) return 'Active'
      if (status === false) return 'Inactive'
      return 'Active'
    },
    resolveUserStatusVariantIcon(status) {
      if (status === true) return 'CheckIcon'
      if (status === false) return 'XIcon'
      return 'CheckIcon'
    },
  },
}
</script>

<style scoped>

</style>
